class Navigation {
  private readonly body: HTMLBodyElement | null;

  private readonly nav: HTMLElement | null;

  private readonly content: HTMLElement | null | undefined;

  private readonly toggler: HTMLElement | null | undefined;

  private active: boolean;

  private innerWidth: number;

  private readonly window: Window;

  private readonly menuItemSubmenus: NodeListOf<HTMLElement> | undefined;

  public constructor(nav: HTMLElement | null) {
    this.body = document.querySelector("body");
    this.nav = nav;
    this.content = nav?.querySelector(".nav-content");
    this.toggler = nav?.querySelector(".nav-toggler");
    this.active = false;
    this.window = window;

    this.innerWidth = window.innerWidth;

    this.menuItemSubmenus = nav?.querySelectorAll(
      ".menu-item--dropdown .menu-item-link"
    );

    console.log(this.menuItemSubmenus);

    this.toggler?.addEventListener("click", () => {
      this.toggleMenu();
    });

    this.window.addEventListener("resize", () => {
      this.innerWidth = window.innerWidth;
    });

    this.menuItemSubmenus?.forEach((menuItemSubmenu: HTMLElement) => {
      if (this.innerWidth < 992) {
        menuItemSubmenu.addEventListener("click", (event: Event) => {
          this.menuItemClicked(event, menuItemSubmenu);
        });
      }
    });

    document.addEventListener("scroll", () => {
      if (this.window.scrollY > 0) {
        this.nav?.classList.add("nav--sticky");
      } else {
        this.nav?.classList.remove("nav--sticky");
      }
    });
  }

  private toggleMenu(): void {
    this.toggleActive(this.toggler, "nav-toggler--active");
    this.toggleActive(this.content, "nav-content--active");
    this.toggleActive(this.nav, "nav--active");
    this.toggleActive(this.body, "nav-is-open");
    this.active = !this.active;
  }

  private toggleActive(
    el: HTMLElement | null | undefined,
    className: string
  ): void {
    el?.classList[this.active ? "remove" : "add"](className);
  }

  private menuItemClicked(event: Event, menuItem: HTMLElement): void {
    event.preventDefault();
    const parent = menuItem.parentElement!;
    parent.classList.toggle("menu-item-link--active");
    parent
      .querySelector(".menu-item--dropdown-wrapper")
      ?.classList.toggle("menu-item--dropdown-wrapper--active");
  }
}

function navigationInit(): void {
  new Navigation(document.querySelector(".nav"));
}

export default navigationInit;
